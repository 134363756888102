<template>
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/">Dashboard</router-link>
        </li>


        <li class="nav-item"
            v-if="user && [TipoUsuario.ADMINISTRADOR, TipoUsuario.MASTER].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/lojas">Lojas</router-link>
        </li>

        <li class="nav-item" v-if="user && [TipoUsuario.ADMINISTRADOR, TipoUsuario.MASTER].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/usuarios">Usuários</router-link>
        </li>
        <li class="nav-item" v-if="user && [TipoUsuario.ADMINISTRADOR, TipoUsuario.MASTER, TipoUsuario.COORDENADOR].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page"
                to="/inventarios">Inventários</router-link>
        </li>

        <li class="nav-item"
            v-if="user && [TipoUsuario.ADMINISTRADOR, TipoUsuario.MASTER, TipoUsuario.COORDENADOR].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page"
                to="/resultados">Resultados</router-link>
        </li>

        <li class="nav-item d-md-none" v-if="user && [TipoUsuario.ADMINISTRADOR, TipoUsuario.MASTER].includes(user.tipo)">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#configuracoes-collapse" role="button"
                aria-expanded="false" aria-controls="configuracoes-collapse" active-class="active" aria-current="page"
                @click="toggleMenuConfig">Configurações</a>
            <ul :class="{ 'show': show_menu_config }" class="navbar-nav ps-4 me-auto mb-2 mb-lg-0 collapse"
                id="configuracoes-collapse">

          
                <li class="nav-item">
                    <router-link to="/setores" aria-current="page" :active-class="'active'" class="nav-link"
                        href="#">Setores e
                        Auditagem Mínima</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/configuracoes/campos-gerais" aria-current="page" :active-class="'active'"
                        class="nav-link" href="#">Campos
                        Gerais</router-link>
                </li>
            </ul>
        </li>

    </ul>
</template>
<script setup lang="ts">
import { current_user as user } from "@/store/auth";
import { TipoUsuario } from "@/store/usuarios";
import { ref } from "vue";
import { useRouter } from "vue-router";

const show_menu_config = ref<boolean>(false)
const toggleMenuConfig = () => {
    show_menu_config.value = !show_menu_config.value
}

const router = useRouter()
router.beforeEach(() => {
    show_menu_config.value = false
})
</script>
