<template>
    <nav class="navbar navbar-expand-lg p-0 fixed-top navbar-dark bg-primary" aria-label="Main navigation">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <Logo class="logo" />
            </router-link>
            <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation"
                @click="toggleMenu">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse offcanvas-collapse" :class="{ open: showMenu }" id="navbarsExampleDefault">
                <Menu />
                <Saudacao></Saudacao>
                
                
                <div class="dropdown d-none d-lg-block" v-if="user && [TipoUsuario.ADMINISTRADOR, TipoUsuario.MASTER, TipoUsuario.COORDENADOR].includes(user.tipo)" >
                    <button class="btn text-white dropdown-toggle" @click="toggleDropdownConfiguracoes"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <GearIcon width="1em" height="1em" viewBox="0 0 16 16"></GearIcon>
                    </button>
                    <ul :class="{ 'show': show_dropdown_configuracoes }" class="dropdown-menu dropdown-menu-dark p-3"
                        aria-labelledby="dropdownMenuButton1">

                        <li><router-link to="/setores" :active-class="'fw-bold'"  class="dropdown-item text-white" href="#">Setores e
                                Auditagem<br />Mínima</router-link></li>
                        <li><router-link to="/configuracoes/campos-gerais" :active-class="'fw-bold'" class="dropdown-item text-white"
                                href="#">Campos Gerais</router-link></li>
                    </ul>
                </div>
                <button class="btn text-white" @click="signout">
                    <DoorOutIcon width="1em" height="1em" viewBox="0 0 30 30" ></DoorOutIcon>
                </button>
            </div>
        </div>
    </nav>
</template>
<script lang="ts" setup>

import { auth_service, current_user as user } from '@/store/auth'
import { TipoUsuario } from "@/store/usuarios";

import Logo from '@/assets/logo.svg'
import Menu from '@/components/Menu.vue'

import GearIcon from '@/assets/icons/gear.svg?component'
import DoorOutIcon from '@/assets/icons/door-out.svg?component'

import Saudacao from '@/components/Saudacao.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const $router = useRouter()

const showMenu = ref<boolean>(false)
const show_dropdown_configuracoes = ref<boolean>(false)

const toggleDropdownConfiguracoes = () => {
    show_dropdown_configuracoes.value = !show_dropdown_configuracoes.value
}

const toggleMenu = () => {
    showMenu.value = !showMenu.value
}

const signout = async () => {
    await auth_service.logout()
    $router.replace('/login')
}

$router.beforeEach(() => {
    show_dropdown_configuracoes.value = false
    showMenu.value = false
})

</script>
<style lang="less" >
.bi {
    font-size: 32px;
}

.dropdown-menu {
    right: 0;
    top: ~"calc(100% + 1em)";
    background-color: #0f61b2 !important;

    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 1em solid transparent;
        border-right: 1em solid transparent;
        border-bottom: 1em solid #0f61b2;
        position: absolute;
        right: 1.5em;
        top: -1em;
    }

}
</style>